import { useContext } from 'react';
import { TrackingContext } from '../hooks/useTracking';
import { setAssetPath, setWebviewPath } from '../utils/helpers';

export function Seasonal({ seasonal, isWebview = false }) {

  const { trackInternalClick } = useContext(TrackingContext);

  const href = setWebviewPath(setAssetPath(`/${seasonal.name}`), isWebview);

  const onClickSeasonal = () => {
    trackInternalClick({
      subunit_name: 'seasonal',
      item_name: seasonal.name,
      item_type: 'image',
      target_content_type: 'buzz',
      target_content_id: `huffpost_horoscopes_${seasonal.name}`,
      referrer_uri: document.referrer,
    });
  };

  return (
    <a
      href={href}
      className='season'
      onClick={(e) => {
        onClickSeasonal(e);
      }}
    >
      <h2 className='season__title'>
        It&apos;s <span className='season__sign'>{seasonal.name}</span> Season
      </h2>
      <div className='season__constellation'>
        <img
          className='season__constellation__img'
          src={seasonal.constellationSrc}
          alt={`Constellation for ${seasonal.name}`}
          width={270}
          height={200}
        />
      </div>
    </a>
  );
};

export default Seasonal;
