import Edition, { gsProps, gsPaths } from '../../components/Edition';

const EDITIONS = ['us', 'uk'];

export default function Page(props) {
  return <Edition {...props} />;
}

export async function getStaticPaths() {
  return gsPaths(EDITIONS);
}

export async function getStaticProps({ params }) {
  return gsProps(params);
}
